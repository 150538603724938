import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { APIResult, emptyAPIResult, loadingAPIResult, successAPIResult, errorAPIResult, defaultError, ApiError } from '../../lib/redux';
import { ChangePickupPriorityParams, triplePSDatasource } from '../../datasource';
import { PickUp, PickUpStatus, ScheduleItemType } from '../../models';

import { createScheduleItem } from '../Schedules/scheduleSlice';
import { logout } from '../Auth';

export interface PickUpsState {
  getPickups: APIResult<PickUp[]>;
  changePickupPriorityAPI: APIResult<PickUp>;

  pickups: PickUp[];
}

const initialState: PickUpsState = {
  getPickups: emptyAPIResult(),
  changePickupPriorityAPI: emptyAPIResult(),

  pickups: []
}

export const getPickups = createAsyncThunk(
  'pickups/getPickups',
  async (date: string) => {
    return triplePSDatasource.getPickups(date);
  }
);

export const changePickupPriority = createAsyncThunk<PickUp, ChangePickupPriorityParams,{ rejectValue: ApiError }>(
  'pickups/changePickupPriority',
  async (params, { rejectWithValue }) => {
    try {
      const data = await triplePSDatasource.changePickupPriority(params);

      return data;
    } catch (err: any) {
      const error = err.response.data?.errors?.[0] || defaultError;

      return rejectWithValue(error);
    }
  }
);

export const pickupsSlice = createSlice({
  name: 'pickups',
  initialState,
  reducers: {
    pickupUnscheduled(state, payload) {
      const pickup = state.pickups.find(p => p.id === payload.payload);
      if (!!pickup) {
        pickup.status = PickUpStatus.CREATED;
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getPickups.pending, state => {
        state.getPickups = loadingAPIResult();
      })
      .addCase(getPickups.fulfilled, (state, action) => {
        state.getPickups = successAPIResult(action.payload);
        state.pickups = action.payload;
      })
      .addCase(getPickups.rejected, (state, action) => {
        state.getPickups = errorAPIResult(action.error);
      })
      .addCase(changePickupPriority.pending, state => {
        state.changePickupPriorityAPI = loadingAPIResult();
      })
      .addCase(changePickupPriority.fulfilled, (state, action) => {
        state.changePickupPriorityAPI = successAPIResult(action.payload);
        state.pickups = state.pickups.map(p => p.id === action.payload.id ? action.payload : p);
      })
      .addCase(changePickupPriority.rejected, (state, action) => {
        state.changePickupPriorityAPI = errorAPIResult(action.error);
      })
      .addMatcher(
        isAnyOf(
          createScheduleItem.fulfilled
        ),
        (state, action) => {
          if (action.payload.type === ScheduleItemType.PICK_UP) {
            const pickup = state.pickups.find(p => p.id === action.payload.pickupId);

            if (!!pickup) {
              pickup.status = PickUpStatus.SCHEDULED;
            }
          }
        } 
      )
      .addMatcher(
        isAnyOf(
          logout
        ),
        state => {
          state.getPickups = initialState.getPickups;
          state.pickups = initialState.pickups;
        }
      )
  }
});

export const { pickupUnscheduled } = pickupsSlice.actions;
export default pickupsSlice.reducer;
