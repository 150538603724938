import { Entity } from './Entity';

export interface Address extends Entity {
  userId: string;
  address: string;
  zip: string;
}

export const addressToHuman = (address?: Address): string => {
  if (!address) return '';

  return `${address.address}, ${address.zip}`;
}
