import React from 'react';
import Modal from 'react-modal';
import { Field, Form, Formik } from 'formik';
import { Oval } from 'react-loader-spinner';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { isValidPhoneNumber } from 'libphonenumber-js';
import moment from 'moment';

import { store } from '../../../app/store';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { setShowOrderDetailsModal, getOrder, updateOrder } from '../ordersSlice';
import { ReactComponent as Close } from '../../../assets/images/close.svg';
import { CleanFillMaterialsDisposing, DumpsterSize, MaterialDisposing, Order, OrderStatus, ServiceType } from '../../../models';
import { RadioButton } from '../../Shared';
import { UpdateOrderFormModel } from '../../../datasource';
import { isAdminSelector } from '../../Auth/authSlice';

import './OrderDetailsModal.scss';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "#FFFFFF",
    height: "calc(100% - 100px)"
  },
};

export function OrderDetailsModal() {
  const dispatch = useAppDispatch();

  const orderId = useAppSelector(state => state.orders.showOrderDetailsModal);
  const order = useAppSelector(state => state.orders.orders.find(o => o.id === orderId));
  const getOrderAPI = useAppSelector(state => state.orders.getOrder);
  const updateOrderAPI = useAppSelector(state => state.orders.updateOrder);

  const isAdmin = isAdminSelector(store.getState());

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('First name is required'),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .test({
        name: 'phone test',
        message: 'Phone number must be valid US number.',
        test: value => !!value && isValidPhoneNumber(value, 'US')
      }),
    email: Yup.string()
      .email('Invalid email')
      .required('Email is required'),
    address: Yup.string()
      .required('Address is required'),
    deliveryDate: Yup.string()
      .required('Delivery date is required')
      .test({
        name: 'date test',
        message: 'Delivery date must be on a weekday.',
        test: value => {
          if (!value) {
            return true;
          }

          if (isAdmin) {
            return true;
          }

          const date = new Date(value);
          return date.getDay() != 6 && date.getDay() != 5;
        }
      })
      .test({
        name: 'date test 2',
        message: 'Delivery date can not be today or before.',
        test: value => {
          if (!value) {
            return true
          }

          if (order?.status != OrderStatus.OrderStatusReceived && order?.status != OrderStatus.OrderStatusCreated) {
            return true;
          }

          const date = moment(value, 'YYYY-MM-DD');
          
          let dateToCompare = moment().startOf('day');
          if (isAdmin) {
            dateToCompare = dateToCompare.add(-1, 'day');
          }
          return date.diff(dateToCompare) > 0;
        }
      }),
    pickupDate: Yup.string()
      .required('Pickup date is required'),
    zip: Yup.string()
      .required('ZIP is required')
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(5, 'Must be exactly 5 digits')
      .max(5, 'Must be exactly 5 digits'),
    price: Yup.number()
      .required('Price is required'),
    deliveryFee: Yup.number()
      .required('Delivery fee is required'),
    discount: Yup.number()
      .required('Discount is required'),
    specialInstructions: Yup.string()
      .test('length', 'Must be less than 500 characters', val => !val || (!!val && val.length < 500))
  });

  const initialValues: UpdateOrderFormModel = {
    id: order?.id ?? '',
    userId: order?.userId ?? undefined,
    name: order?.name ?? '',
    address: order?.address ?? '',
    zip: order?.zip ?? '',
    phoneNumber: order?.phoneNumber ?? '',
    email: order?.email ?? '',
    service: order?.service ?? ServiceType.DUMPSTER,
    dumpsterSize: order?.dumpsterSize ?? DumpsterSize.SIZE_10,
    materialDisposing: order?.materialDisposing ?? MaterialDisposing.CONSTRUCTION,
    description: order?.description ?? '',
    deliveryDate: order?.deliveryDate ? moment(order.deliveryDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : '',
    pickupDate: order?.pickupDate ? moment(order.pickupDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : '',
    status: order?.status ?? OrderStatus.OrderStatusReceived,
    price: order?.price ?? 0,
    deliveryFee: order?.deliveryFee ?? 0,
    po: order?.po ?? '',
    discount: order?.discount ?? 0,
    specialInstructions: order?.specialInstructions ?? '',
    paymentType: order?.paymentType,
    additionalCharges: order?.additionalCharges ?? 0
  };

  React.useEffect(() => {
    if (!!orderId && !order) {
      dispatch(getOrder(orderId));
    }
  }, [orderId]);

  React.useEffect(() => {
    if (!updateOrderAPI.loading && !!updateOrderAPI.value) {
      dispatch(setShowOrderDetailsModal(undefined));
    }
  }, [updateOrderAPI.loading, !!updateOrderAPI.error, !!updateOrderAPI.value]);

  const toggleModal = () => {
    dispatch(setShowOrderDetailsModal(undefined));
  }

  const onSubmit = (form: UpdateOrderFormModel) => {
    dispatch(updateOrder(form));
  }

  const orderDetails = (order?: Order): React.ReactNode => {
    if (! order) {
      return null;
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
         {({ values, errors, touched }) => (
          <Form className='update-order-form-component'>
            <div className='fields-scroll-container'>
              <div className='field-container'>
                <div className='label'>Full name:</div>
                <Field id='name' name='name' className='text-input'/>
                {errors.name && touched.name && <div className='form-error'>{errors.name}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>Phone number:</div>
                <Field id='phoneNumber' name='phoneNumber' className='text-input'/>
                {errors.phoneNumber && touched.phoneNumber && <div className='form-error'>{errors.phoneNumber}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>Email:</div>
                <Field id='email' name='email' className='text-input'/>
                {errors.email && touched.email && <div className='form-error'>{errors.email}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>Delivery Address:</div>
                <Field id='address' name='address' className='text-input'/>
                {errors.address && touched.address && <div className='form-error'>{errors.address}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>ZIP code:</div>
                <Field id='zip' name='zip' className='text-input' type='number'/>
                {errors.zip && touched.zip && <div className='form-error'>{errors.zip}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>Delivery date:</div>
                <Field id='deliveryDate' name='deliveryDate' className='text-input' type='date' disabled />
                {errors.deliveryDate && touched.deliveryDate && <div className='form-error'>{errors.deliveryDate}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>Pickup date:</div>
                <Field id='pickupDate' name='pickupDate' className='text-input' type='date' disabled />
                {errors.pickupDate && touched.pickupDate && <div className='form-error'>{errors.pickupDate}</div>}
              </div>

              <div className='field-container'>
                <div className='label'>Price:</div>
                <Field id='price' name='price' className='text-input' type='number'/>
                {errors.price && touched.price && <div className='form-error'>{errors.price}</div>}
              </div>

              {isAdmin && (
                <div className='field-container'>
                  <div className='label'>Delivery fee:</div>
                  <Field id='deliveryFee' name='deliveryFee' className='text-input' type='number'/>
                  {errors.deliveryFee && touched.deliveryFee && <div className='form-error'>{errors.deliveryFee}</div>}
                </div>
              )}

              {isAdmin && (
                <div className='field-container'>
                  <div className='label'>Discount:</div>
                  <Field id='discount' name='discount' className='text-input' type='number'/>
                  {errors.discount && touched.discount && <div className='form-error'>{errors.discount}</div>}
                </div>
              )}

              <div className='field-container'>
                <div className='label'>Service:</div>
                <div className='radio-button-options-container'>
                  <RadioButton name="service" value={ServiceType.DUMPSTER} label='Dumpster rental'/>
                  <RadioButton name="service" value={ServiceType.JUNK} label='Junk removal'/>
                </div>
              </div>

              <div className='field-container'>
                <div className='label'>Size of dumpster:</div>
                <div className='radio-button-options-container'>
                  <RadioButton name="dumpsterSize" value={DumpsterSize.SIZE_10} label='10 yards'/>
                  <RadioButton name="dumpsterSize" value={DumpsterSize.SIZE_15} label='15 yards'/>
                  <RadioButton name="dumpsterSize" value={DumpsterSize.SIZE_CLEAN_FILL} label='Clean Fill Box'/>
                </div>
              </div>

              <div className='field-container'>
                <div className='label'>Material disposing:</div>
                <div className='radio-button-options-container'>
                  {values.dumpsterSize === DumpsterSize.SIZE_CLEAN_FILL && (
                    <>
                      <RadioButton name="materialDisposing" value={CleanFillMaterialsDisposing.BRICK} label='Brick'/>
                      <RadioButton name="materialDisposing" value={CleanFillMaterialsDisposing.BLOCK} label='Block'/>
                      <RadioButton name="materialDisposing" value={CleanFillMaterialsDisposing.DIRT} label='Dirt'/>
                      <RadioButton name="materialDisposing" value={CleanFillMaterialsDisposing.CONCRETE} label='Concrete'/>
                      <RadioButton name="materialDisposing" value={CleanFillMaterialsDisposing.OTHER} label='Other'/>
                    </>
                  )}
                  {(values.dumpsterSize === DumpsterSize.SIZE_10 || values.dumpsterSize === DumpsterSize.SIZE_15) && (
                    <>
                      <RadioButton name="materialDisposing" value={MaterialDisposing.CONSTRUCTION} label='Construction'/>
                      <RadioButton name="materialDisposing" value={MaterialDisposing.HOUSEHOLD} label='Household'/>
                      <RadioButton name="materialDisposing" value={MaterialDisposing.HOUSEHOLD_CONSTRUCTION} label='Mix'/>
                    </>
                  )}
                </div>
              </div>

              {values.service === ServiceType.DUMPSTER && (
                <div className='field-container'>
                  <div className='label'>Description:</div>
                  <Field id='description' name='description' className='text-input'/>
                </div>
              )}

              <div className='field-container'>
                <div className='label'>Special instructions:</div>
                <Field id='specialInstructions' name='specialInstructions' className='text-input' type='textarea'/>
                {errors.specialInstructions && touched.specialInstructions && <div className='form-error'>{errors.specialInstructions}</div>}
              </div>
              
              <div className='field-container'>
                <div className='label'>PO (if applicable):</div>
                <Field id='po' name='po' className='text-input'/>
              </div>
              
              

              {isAdmin && (
                <div className='field-container'>
                  <div className='label'>Status:</div>
                  <div className='radio-button-options-container status-radio-buttons'>
                    <div className='row-radio-buttons'>
                      <RadioButton name="status" value={OrderStatus.OrderStatusCreated} label='Created'/>
                      <RadioButton name="status" value={OrderStatus.OrderStatusScheduled} label='Scheduled'/>
                    </div>
                    <div className='row-radio-buttons'>
                      <RadioButton name="status" value={OrderStatus.OrderStatusDelivered} label='Delivered'/>
                      <RadioButton name="status" value={OrderStatus.OrderStatusScheduledToPickup} label='Scheduled for Pickup'/>
                    </div>
                    <div className='row-radio-buttons'>
                      <RadioButton name="status" value={OrderStatus.OrderStatusComplete} label='Complete'/>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <button type='submit' className='update-order-button'  disabled={updateOrderAPI.loading}>
              Update Order
              {updateOrderAPI.loading && <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div>}
            </button>
          </Form>
         )}
      </Formik>
    );
  };

  return (
    <Modal
      isOpen={!!orderId}
      onRequestClose={toggleModal}
      style={modalStyles}
    >
      <div className='order-details-modal-component'>
        <Close className='close-button' onClick={toggleModal}/>

        <div className='title'>Order Details</div>

        {getOrderAPI.loading
          ? <div style={{ marginLeft: 8}}><Oval color="#131313" height={20} width={20} secondaryColor="#FBD20A"/></div>
          : orderDetails(order)
        }
      </div>
    </Modal>
  );
}
