import React from 'react';

import { DriversWidget } from '../../Drivers';
import { OrdersWidget } from '../../Orders';
import { ScheduleWidget } from '../../Schedules';
import { DumpYardsWidget } from '../../DumpYards';
import { PermanentRentalsWidget } from '../../PermanentRentals/PermanentRentalsWidget/PermanentRentalsWidget';
import { ReceivedOrdersWidget } from '../../ReceivedOrders';

import { useAppDispatch } from '../../../app/hooks';
import { getDrivers, getDriverAddresses } from '../../Drivers/driversSlice';
import { getDashboardOrderStats } from '../../Orders/ordersSlice';
import { getPermanentRentalTemplates } from '../../PermanentRentals/permanentRentalsSlice';
import { getDashboardScheduleStats } from '../../Schedules/scheduleSlice';
import { getOrdersDashboard } from '../../ReceivedOrders/receivedOrdersSlice';

import './AdminDashboard.scss';

export function AdminDashboard() {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getDrivers());
    dispatch(getDriverAddresses());
    dispatch(getDashboardOrderStats());
    dispatch(getDashboardScheduleStats());
    dispatch(getPermanentRentalTemplates());
    dispatch(getOrdersDashboard());
  }, []);

  return (
    <div className='admin-dashboard-component'>
      <ReceivedOrdersWidget />
      <DriversWidget />
      <OrdersWidget />
      <ScheduleWidget />
      <DumpYardsWidget />
      <PermanentRentalsWidget />
    </div>
  );
}
